import React, { useEffect, useState } from "react";
import styled from "styled-components";
import xIcon from "../../assets/images/xIcon.svg";

const Home = () => {
  const [selectedButton, setSelectedButton] = useState<string>("UP");
  const [deposit, setDeposit] = useState<number>(0);
  const [displayedDeposit, setDisplayedDeposit] = useState<string>("0");
  const [displayedRent, setDisplayedRent] = useState<string>("0");
  const [rent, setRent] = useState<number>(0);
  const [interestRate, setInterestRate] = useState<string>("0");
  const [conversionRate, setConversionRate] = useState<string>("0");

  const [decreasingDeposit, setDecreasingDeposit] = useState<string>("");
  const [increasingRent, setIncreasingRent] = useState<string>("");

  const [decreasingRent, setDecreasingRent] = useState<string>("");
  const [increasingDeposit, setIncreasingDeposit] = useState<string>("");

  const [totalDeposit, setTotalDeposit] = useState<string>("");
  const [totalRent, setTotalRent] = useState<string>("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setNumber: React.Dispatch<React.SetStateAction<number>>,
    setDisplayedNumber: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = e.target.value.replace(/,/g, "");
    if (value === "") {
      setNumber(0);
      setDisplayedNumber("");
    } else {
      const numberValue = parseFloat(value);
      if (!isNaN(numberValue)) {
        setNumber(numberValue);
        setDisplayedNumber(numberValue.toLocaleString());
      } else {
        setDisplayedNumber(e.target.value);
      }
    }
  };

  const calculateDeposit = () => {
    const decreasingRent = (rent * parseFloat(conversionRate)) / 100;
    setDecreasingRent(Math.floor(decreasingRent).toLocaleString());
    const increasingDeposit =
      (decreasingRent / (parseFloat(interestRate) / 100)) * 12;
    setIncreasingDeposit(Math.floor(increasingDeposit).toLocaleString());
    setTotalDeposit(Math.floor(increasingDeposit + deposit).toLocaleString());
    setTotalRent(Math.floor(rent - decreasingRent).toLocaleString());
  };

  const resetAll = () => {
    setDeposit(0);
    setDisplayedDeposit("0");
    setRent(0);
    setDisplayedRent("0");
    setInterestRate("0");
    setConversionRate("0");
    setDecreasingDeposit("");
    setIncreasingDeposit("");
    setDecreasingRent("");
    setIncreasingRent("");
    setTotalDeposit("");
    setTotalRent("");
  };

  const calculateRent = () => {
    const decreasingDeposit = (deposit * parseFloat(conversionRate)) / 100;
    setDecreasingDeposit(Math.floor(decreasingDeposit).toLocaleString());
    const increasingRent =
      (deposit *
        (parseFloat(conversionRate) / 100) *
        (parseFloat(interestRate) / 100)) /
      12;
    setIncreasingRent(Math.floor(increasingRent).toLocaleString());
    setTotalDeposit(Math.floor(deposit - decreasingDeposit).toLocaleString());
    setTotalRent(Math.floor(rent + increasingRent).toLocaleString());
  };

  useEffect(() => {
    if (selectedButton === "UP") {
      setInterestRate("7");
    } else if (selectedButton === "DOWN") {
      setInterestRate("3.5");
    }
  }, [selectedButton]);

  return (
    <HomeWrapper>
      <HomeContainer>
        <HomeHeader>보증금 상호전환 계산기</HomeHeader>
        <CalculatorContainer>
          <CalculatorControlButtonWrapper>
            <CalculatorControlButton
              $selectedButton={selectedButton === "UP"}
              onClick={() => {
                setSelectedButton("UP");
                setTotalDeposit("");
                setTotalRent("");
              }}
            >
              보증금 ↑ 임대료 ↓
            </CalculatorControlButton>
            <CalculatorControlButton
              $selectedButton={selectedButton === "DOWN"}
              onClick={() => {
                setSelectedButton("DOWN");
                setTotalDeposit("");
                setTotalRent("");
              }}
            >
              보증금 ↓ 임대료 ↑
            </CalculatorControlButton>
          </CalculatorControlButtonWrapper>
          <CalculatorInputWithTitle
            title={"기본 보증금"}
            unit={"원"}
            onChange={(e) =>
              handleInputChange(e, setDeposit, setDisplayedDeposit)
            }
            value={displayedDeposit}
          />
          <CalculatorInputWithTitle
            title={"기본 임대료"}
            unit={"원"}
            onChange={(e) => handleInputChange(e, setRent, setDisplayedRent)}
            value={displayedRent}
          />
          <CalculatorInputWithTitle
            title={"전환 이율"}
            unit={"%"}
            value={interestRate}
            onChange={(e) => {
              if (e.target.value === "") {
                setInterestRate("");
              } else {
                if (/^\d*\.?\d*$/.test(e.target.value)) {
                  setInterestRate(e.target.value);
                }
              }
            }}
          />
          <CalculatorInputWithTitle
            title={"전환 비율"}
            unit={"%"}
            value={conversionRate.toString()}
            onChange={(e) => {
              if (e.target.value === "") {
                setConversionRate("");
              } else {
                if (/^\d*\.?\d*$/.test(e.target.value)) {
                  setConversionRate(e.target.value);
                }
              }
            }}
          />
          <CalculatorButtonWrapper>
            <CalculatorResetButton onClick={resetAll}>
              초기화
            </CalculatorResetButton>
            <CalculatorRunButton
              onClick={
                selectedButton === "UP" ? calculateDeposit : calculateRent
              }
            >
              계산하기
            </CalculatorRunButton>
          </CalculatorButtonWrapper>
        </CalculatorContainer>
        {Boolean(totalDeposit) && Boolean(totalRent) && (
          <ResultContainer>
            <ResultWithTitle
              title="보증금"
              total={`${totalDeposit}원`}
              value={
                selectedButton === "UP"
                  ? `${increasingDeposit}원 ↑`
                  : `${decreasingDeposit}원 ↓`
              }
            />
            <ResultWithTitle
              title="임대료"
              total={`${totalRent}원`}
              value={
                selectedButton === "UP"
                  ? `${decreasingRent}원 ↓`
                  : `${increasingRent}원 ↑`
              }
            />
            <ResultXIcon
              src={xIcon}
              alt="Close Icon"
              onClick={() => {
                setTotalDeposit("");
                setTotalRent("");
              }}
            />
          </ResultContainer>
        )}
      </HomeContainer>
    </HomeWrapper>
  );
};

export default Home;

const HomeWrapper = styled.div`
  padding: 0 40px 40px;
  @media (max-width: 600px) {
    padding: 0 20px 40px;
  }
`;

const HomeContainer = styled.div`
  width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media (max-width: 1080px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    min-width: 270px;
  }
`;

const HomeHeader = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  font-weight: 700;
  color: #505866;
  @media (max-width: 1080px) {
    font-size: 24px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const CalculatorContainer = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 24px 20px;
`;

const CalculatorControlButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const CalculatorControlButton = styled.button<{ $selectedButton: boolean }>`
  border: ${(props) =>
    props.$selectedButton ? " 1px solid #649CF7" : " 1px solid #dbdcdc"};
  background-color: ${(props) => (props.$selectedButton ? "#F4F8FE" : "#fff")};
  color: ${(props) => (props.$selectedButton ? "#0D66F5" : "#505866")};
  padding: 16px 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  flex: 1;
`;

const CalculatorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 14px;
  margin-top: 24px;
`;

const CalculatorInputWithTitle = ({
  title,
  unit,
  onChange,
  value,
}: {
  title: string;
  unit: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  maxLength?: number;
}) => {
  const id = title.replace(/\s/g, "");
  return (
    <CalculatorInputWrapper>
      <CalculatorInputLabel htmlFor={id}>{title}</CalculatorInputLabel>
      <CalculatorInput id={id} onChange={onChange} value={value} />
      <CalculatorInputSpan>{unit}</CalculatorInputSpan>
    </CalculatorInputWrapper>
  );
};

const CalculatorInputWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  position: relative;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CalculatorInputLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #505866;
  text-align: right;
  width: 80px;
  @media (max-width: 600px) {
    text-align: left;
  }
`;

const CalculatorInputSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #333d4b;
  position: absolute;
  right: 16px;
  @media (max-width: 600px) {
    right: 16px;
    bottom: 16px;
  }
`;

const CalculatorInput = styled.input`
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #e5e7ea;
  flex: 1;
  outline: none;
  &:focus {
    border: 1px solid #2172f4;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CalculatorRunButton = styled.button`
  background-color: #4880ee;
  padding: 14px 24px;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
`;

const CalculatorResetButton = styled.button`
  color: #4880ee;
  padding: 14px 24px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
`;

const ResultContainer = styled.div`
  background-color: #4880ee;
  border-radius: 20px;
  padding: 24px 20px 4px;
  position: relative;
`;

const ResultWithTitle = ({
  title,
  total,
  value,
}: {
  title: string;
  total: string;
  value: string;
}) => {
  return (
    <ResultWrapper>
      <ResultTitleWrapper>
        <ResultTitleP>{title}</ResultTitleP>
        <ResultSubTitleP>{value}</ResultSubTitleP>
      </ResultTitleWrapper>
      <ResultP>{total}</ResultP>
    </ResultWrapper>
  );
};

const ResultWrapper = styled.div`
  margin-bottom: 20px;
`;

const ResultTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const ResultTitleP = styled.p`
  color: #c9e2ff;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ResultSubTitleP = styled.p`
  color: #fff;
  font-size: 20px;
  margin-left: 8px;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ResultP = styled.p`
  color: #fff;
  font-size: 40px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const ResultXIcon = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
`;
