import { GlobalStyle } from "../src/styles/GlobalStyle";
import AppRoutes from "./router/AppRoutes";

function App() {
  return (
    <>
      <GlobalStyle />
      <AppRoutes />
    </>
  );
}

export default App;
