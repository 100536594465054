import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`

  ${reset}

  :root {
    font-family: 'Pretendard';
    background-color: #F5F5F5;
  }
  
  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
  a, dl, dt, dd, ol, ul, li, form, label, table {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ol, ul, li {
    list-style: none;
  }

  button {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    font-family: 'Pretendard';
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard';
    font-size: 16px;
  }
`;
